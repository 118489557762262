<div
  class="cursor-pointer select-none py-[10px] px-[24px] text-R900 flex justify-center items-center gap-2 font-medium text-b1 text-nowrap hover:bg-G200 rounded-xl transition-all duration-300"
>
  <img
    src="/assets/massimo/svg/alertOctagon.svg"
    alt="alert"
    class="w-[20px] h-[20px] select-none"
  />
  Raise Dispute
</div>
