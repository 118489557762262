<main>
  <section class="section bg-gray">
    <div class="container text-center card px-30 py-[82px] my-10">
      <p class="text-h4 pb-3">Select how you would like to unlock</p>
      <div class="flex rounded-sm p-3">
        <div class="w-full md:w-1/3 col-span-3 min-h-[400px]">
          <ul class="tabs">
            <li
              (click)="selected = WalletApp.MetaMask"
              [class.active]="isActive(WalletApp.MetaMask)"
            >
              MetaMask
            </li>
            <li
              (click)="selected = WalletApp.WalletConnectBsc"
              [class.active]="isActive(WalletApp.WalletConnectBsc)"
            >
              WalletConnect
            </li>
          </ul>
        </div>
        <div class="w-full md:w-1/2 text-left pl-5" [ngSwitch]="selected">
          <div *ngSwitchCase="WalletApp.MetaMask">
            <div>
              Click to connect to MetaMask wallet.
              <div class="mt-[20px] w-[120px]">
                <basic-button
                  (click)="connect(WalletApp.MetaMask)"
                  [type]="2"
                  title="Connect"
                  size="small"
                />
              </div>
              <div class="keystore-error">
                {{ bscError }}
              </div>
            </div>
          </div>
          <div *ngSwitchCase="WalletApp.WalletConnectBsc">
            <div>
              Click to connect to WalletConnect compatible wallet (i.e. Trust)
              <p *ngIf="isAnotherBscChain">
                Pay attention: you'll connect to BSC MainNet, cause testnet is
                not supported
              </p>
              <div class="mt-[20px] w-[120px]">
                <basic-button
                  *ngIf="!walletconnectConnecting"
                  (click)="connect(WalletApp.WalletConnectBsc)"
                  [type]="2"
                  title="Connect"
                  size="small"
                />
                <basic-button
                  *ngIf="walletconnectConnecting"
                  (click)="connect(WalletApp.WalletConnectBsc)"
                  [type]="2"
                  title="Connect..."
                  size="small"
                />
              </div>
              <div class="keystore-error">
                {{ bscError }}
              </div>
            </div>
          </div>
          <div *ngSwitchCase="WalletApp.WalletConnect">
            <div *ngIf="!isTestnet(); else testnet">
              Click to scan a QR code and link your mobile wallet using
              WalletConnect.
              <div class="mt-[20px] w-[120px]">
                <basic-button
                  *ngIf="!walletconnectConnecting"
                  (click)="connect(WalletApp.WalletConnectBsc)"
                  [type]="2"
                  title="Wallet Connect"
                  size="small"
                />
              </div>
            </div>
            <ng-template #testnet>
              WalletConnect doesn't support testnet
            </ng-template>
          </div>
          <div *ngSwitchCase="WalletApp.Ledger">
            <div class="row">
              <div class="col-12">Connect your Ledger device</div>
            </div>
            <div class="row mt-30">
              <div class="col-1">
                <img src="/assets/img/asset-step1.svg" />
              </div>
              <div class="col-7">Enter PIN Code</div>
              <div class="">
                <img src="/assets/img/asset-ledger-pin.svg" />
              </div>
            </div>
            <div class="row mt-30">
              <div class="col-1">
                <img src="/assets/img/asset-step2.svg" />
              </div>
              <div class="col-7">
                <div>Open Binance Chain</div>
                <div class="ledger-app-notice">
                  “Binance Chain Ready” must be on-screen
                </div>
              </div>
              <div class="">
                <img src="/assets/img/asset-ledger-app.svg" />
              </div>
            </div>
            <div class="row mt-40">
              <div class="col-4 ledger-links">
                <div>
                  <a
                    href="https://www.binance.org/static/guides/DEX-Ledger-Documentation.html"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    App Installation & Usage Instructions
                  </a>
                </div>
                <div>
                  <a
                    href="https://support.ledger.com/hc/en-us/articles/115005165269-Connection-issues-with-Windows-or-Linux"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Having Connection Issues?
                  </a>
                </div>
              </div>
              <div class="col-4">
                <div class="ledger-index">Index Number (default 0)</div>
                <div>
                  <input
                    class="ledger-index-input"
                    [(ngModel)]="ledgerIndex"
                    type="number"
                    min="0"
                  />
                </div>
              </div>
              <div class="col-4">
                <button class="btn btn-primary" (click)="connectLedger()">
                  Unlock
                </button>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="WalletApp.Keystore">
            <div class="mb-10">Select your keystore file</div>
            <div class="mb-30">
              <label for="file-upload" class="btn btn-primary">
                Upload keystore file
              </label>
              <i
                *ngIf="validKeystoreUploaded"
                class="fa fa-check-circle keystore-check"
              ></i>
              <input
                id="file-upload"
                type="file"
                hidden
                (change)="uploadFile($event)"
              />
              <div class="keystore-error">
                {{ keystoreError }}
              </div>
            </div>
            <div class="mb-10">
              <input
                [(ngModel)]="keystorePassword"
                type="password"
                (ngModelChange)="resetUnlocking()"
                placeholder="Enter wallet password"
                class="keystore-password"
              />
            </div>
            <div *ngIf="unlockingFailed" class="keystore-error">
              Incorrect Password
            </div>
            <div>
              <button
                (click)="unlockKeystore()"
                [disabled]="!validKeystoreUploaded || !keystorePassword"
                class="btn btn-primary keystore-unlock"
              >
                Unlock wallet now
              </button>
            </div>
          </div>
          <div *ngSwitchCase="WalletApp.Mnemonic">
            <p class="mnemonic-warning">
              <i
                ><b>Warning!</b> Entering your seed phrase or private key on any
                website is very dangerous. If you have malicious extensions
                installed in your browser or accidentally visit a phishing
                website, your assets can be stolen.</i
              >
            </p>
            <p>
              Recover your Keystore file instead, since it is password
              protected.
            </p>
            <a href="https://www.binance.org/en/recover" target="_blank">
              <button class="btn btn-primary">Recover Keystore</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<!-- 
<div
  class="modal fade"
  id="replaceWalletModal"
  role="dialog"
  data-keyboard="true"
  data-backdrop="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Replacing your wallet</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to replace your wallet?</p>
        <p>
          Old: <b>{{ walletReplacement.old }}</b>
        </p>
        <p>
          New: <b>{{ walletReplacement.new }}</b>
        </p>
      </div>
      <div class="modal-footer">
        <button
          (click)="onConfirmWalletUpdate()"
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
        >
          Yes
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="replaceWalletModalBsc"
  role="dialog"
  data-keyboard="true"
  data-backdrop="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Replacing your Bsc wallet</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to replace your Bsc wallet?</p>
        <p>
          Old: <b>{{ walletReplacementBsc.old }}</b>
        </p>
        <p>
          New: <b>{{ walletReplacementBsc.new }}</b>
        </p>
      </div>
      <div class="modal-footer">
        <button
          (click)="onConfirmWalletUpdateBsc()"
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
        >
          Yes
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div> -->
