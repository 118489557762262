<a
  href="{{ url }}"
  target="_blank"
  class="flex items-center hover:underline gap-[8px] w-max transition-none hover:decoration-C600"
>
  <img
    src="/assets/massimo/svg/ion_attach-outline.svg"
    alt="attach"
    class="w-[24px] h-[24px] select-none"
  />
  <div class="text-C600 text-b2 font-sans">{{ name }}</div>
</a>
