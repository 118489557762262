<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  color="#3AC2FF"
  type="ball-clip-rotate-pulse"
></ngx-spinner>

<app-header />
<p-toast position="bottom-right"></p-toast>

<div class="font-sans min-h-[400px]">
  <router-outlet />
</div>

<app-footer />
