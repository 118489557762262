<div
  class="group flex items-center text-b1 text-G900 font-medium cursor-pointer p-[6px] rounded-md w-max"
  [routerLink]="['/jobs']"
>
  <img
    src="/assets/massimo/svg/Arrow-Left.svg"
    alt="Arrow-Left"
    class="w-[20px] h-[20px] mx-[8px] group-hover:-translate-x-[6px] duration-300 transition-all"
  />
  Back to Job Board
</div>
