<main class="font-sans bg-white sm:bg-G50">
  <div class="flex justify-center">
    <!-- template start -->
    <div
      class="max-w-[1440px] w-full pt-[56px] sm:pt-[56px] sm:pb-[8px] px-[24px] md:px-[64px]"
    >
      <div
        *ngIf="!job && !loading"
        class="flex flex-col md:flex-row md:justify-between gap-2 md:gap-6 pt-[56px]"
      >
        <div class="w-full rounded-[12px] bg-white border border-G300 p-11">
          <div class="text-h6 md:text-h5 text-G800 font-medium">
            We can't find the job you were looking for<br />
            Please check the link you provided<br />
            <button
              class="btn btn-primary mt-10 mr-10 text-[15px] py-[7px]"
              [routerLink]="['/home']"
            >
              RETURN TO HOMEPAGE
            </button>
          </div>
        </div>
      </div>

      <div
        *ngIf="job"
        class="flex flex-col lg:flex-row md:justify-between gap-[16px] md:gap-[24px] mt-[36px] sm:mt-[56px]"
      >
        <div class="w-full">
          <ng-container *ngIf="(isShownTab || isMyJob) && isPublic">
            <div
              class="max-sm:flex items-center justify-center max-sm:mb-[16px]"
            >
              <p-tabMenu
                [model]="activeJobTypes"
                [activeItem]="selectedJob"
                *ngIf="!isProvider || isMyJob"
              >
                <ng-template pTemplate="item" let-item let-i="index">
                  <div
                    class="bg-G200 border-t hidden sm:block"
                    [ngClass]="{
                      'rounded-tl-xl border-l': i === 0,
                      'rounded-tr-xl border-r': i === 1
                    }"
                  >
                    <div
                      class="select-none flex items-center h-full py-[8px] px-[12px] md:px-[40px] text-b2 md:text-b1 text-center font-medium rounded-t-xl text-G500 cursor-pointer transition-colors duration-200"
                      (click)="changeJob(item)"
                      [ngClass]="{ 'bg-white': selectedJob.code === item.code }"
                    >
                      {{ item.label }}
                    </div>
                  </div>
                  <div
                    class="bg-G200 p-[4px] block sm:hidden"
                    [ngClass]="{
                      'rounded-tl-xl rounded-bl-xl border-l': i === 0,
                      'rounded-tr-xl rounded-br-xl border-r': i === 1
                    }"
                  >
                    <div
                      class="select-none flex items-center h-full text-b2 text-G500 font-medium cursor-pointer py-[8px] px-[24px] transition-colors duration-200 rounded-[12px]"
                      (click)="changeJob(item)"
                      [ngClass]="{
                        'bg-white': selectedJob.code === item.code
                      }"
                    >
                      {{ item.label }}
                    </div>
                  </div>
                </ng-template>
              </p-tabMenu>
            </div>
          </ng-container>

          <div *ngIf="selectedJob.code === 'jobsdetail'" class="w-full">
            <div
              class="w-full sm:rounded-[12px] sm:bg-white sm:border sm:border-G300 sm:p-[40px]"
              [ngClass]="{
                '!rounded-tl-none': isShownTab || (isMyJob && !yourApplication)
              }"
            >
              <job-details-panel
                [job]="job"
                [isJobDetailsShow]="isShownTab || isMyJob"
              />

              <!-- bid start -->
              <ng-container *ngIf="canBid">
                <form [formGroup]="bidForm" class="mt-[40px]" novalidate>
                  <div
                    class="flex flex-col gap-4 border-t border-G300"
                    *ngIf="job && currentUser && isProvider && !isMyJob"
                  >
                    <div
                      class="mt-[40px] md:mt-[32px] text-h6 md:text-h5 text-G900 font-medium"
                    >
                      Submit your proposal
                    </div>

                    <div class="flex flex-col gap-[8px]">
                      <div class="text-b1 font-medium text-G900">
                        Cover Letter
                      </div>
                      <div class="w-full">
                        <angular-editor
                          formControlName="message"
                          [config]="coverLetterConfig"
                          class="angular-editor"
                        ></angular-editor>
                        <div class="flex justify-end mt-[8px]">
                          <div class="text-right text-b2 text-G700">
                            {{
                              stripHtmlTagslength(this.bidForm.value.message)
                            }}/2500
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="bidMessageValidated">
                      <warning-message
                        message="Your coverletter should be longger than 5 and shorter than 2500"
                        [type]="1"
                      />
                    </div>

                    <div
                      class="flex flex-col gap-4 pt-[16px] sm:pt-[24px] border-t border-G300"
                    >
                      <div
                        class="flex max-md:flex-col justify-center md:justify-between gap-[8px]"
                      >
                        <div class="text-b1 font-medium">Your Payment</div>
                        <div class="text-b1 font-medium">
                          Client's budget is ${{
                            job.budget | number : '1.1-2'
                          }}
                          <span *ngIf="job.paymentType !== 'Fixed price'"
                            >/hr </span
                          >USD
                        </div>
                      </div>

                      <!-- Bid Price -->
                      <div
                        class="flex max-md:flex-col justify-center md:justify-between gap-[8px]"
                      >
                        <div>
                          <div class="text-b1 font-medium">Bid Price</div>
                          <p class="text-b2 text-G500">
                            Total amount the client will see on your proposal
                          </p>
                        </div>

                        <div
                          class="flex justify-between border rounded-xl border-G300 py-[10px] px-[16px] bg-G50 w-full md:w-[240px] h-max"
                        >
                          <div class="flex items-center w-full">
                            $
                            <input
                              formControlName="price"
                              type="number"
                              id="price"
                              min="1"
                              [(ngModel)]="bidPrice"
                              class="!outline-none !border-none bg-G50 pl-[2px] w-full"
                            />
                          </div>
                          USD
                        </div>
                      </div>
                      <!-- 1% Service Fee -->
                      <div
                        class="flex max-md:flex-col justify-center md:justify-between gap-[8px]"
                      >
                        <div>
                          <div class="text-b1 font-medium">1% Service Fee</div>
                          <p class="text-b2 text-G500">
                            CanWork charges 1% fees. The lowest fees ever.
                          </p>
                        </div>

                        <div
                          class="flex justify-between border rounded-xl border-G300 py-[10px] px-[16px] bg-G200 w-full md:w-[240px] h-max"
                        >
                          <div>$ {{ bidPrice / 100 | number : '1.1-2' }}</div>
                          USD
                        </div>
                      </div>

                      <!-- Payout -->
                      <div
                        class="flex max-md:flex-col justify-center md:justify-between h-max gap-[8px]"
                      >
                        <div>
                          <div class="text-b1 font-medium">Payout</div>
                          <p class="text-b2 text-G500">
                            Amount you'll receive after service fee charge
                          </p>
                        </div>

                        <div
                          class="flex justify-between border rounded-xl border-G300 py-[10px] px-[16px] bg-G50 w-full md:w-[240px] h-max"
                        >
                          <div>$ {{ bidPrice * 0.99 | number : '1.1-2' }}</div>
                          USD
                        </div>
                      </div>

                      <!-- message -->
                      <warning-message
                        *ngIf="bidPrice > job.budget"
                        message="Your bill is more than the client's budget, consider adjusting for better chance of success"
                        [type]="1"
                      />

                      <warning-message
                        *ngIf="1 > bidPrice || priceValidated"
                        message="Your price should be bigger than zero"
                        [type]="1"
                      />

                      <div
                        class="w-full flex flex-col gap-[8px] border-t border-G300 pt-[16px] sm:pt-[24px]"
                      >
                        <div class="text-b1 text-G900 font-medium">
                          Attachments
                        </div>
                        <div class="text-b2 text-G500 text-wrap font-sans">
                          You may attach up to 10 files under the size of 25 MB
                          each. Include work samples or other documents to
                          support your application.
                        </div>

                        <div
                          (dragover)="onDragOver($event)"
                          (dragleave)="onDragLeave($event)"
                          (drop)="onDrop($event)"
                          [class]="
                            hoveredFiles && !isCurrentUpload
                              ? 'w-full rounded-xl bg-G500 text-white border border-G200 flex justify-center items-center py-[10px] px-[16px]'
                              : 'w-full rounded-xl bg-G50 text-G90 border border-G200 flex justify-center items-center py-[10px] px-[16px]'
                          "
                        >
                          <div class="w-full">
                            <div
                              class="flex justify-center items-center select-none"
                              [ngClass]="{
                                'cursor-not-allowed': isCurrentUpload
                              }"
                            >
                              <img
                                src="/assets/massimo/svg/fi_plus-circle.svg"
                                alt="fi_info"
                                class="pr-[8px] select-none"
                              />
                              Drag or
                              <label
                                class="text-b1 text-G900"
                                for="file-upload"
                                class="!text-C500 !text-b1 !font-sans !m-0 underline px-[3px] cursor-pointer"
                                [ngClass]="{
                                  '!text-G500 cursor-not-allowed':
                                    isCurrentUpload,
                                  '!text-white': hoveredFiles
                                }"
                              >
                                Upload
                              </label>
                              your files
                            </div>
                            <input
                              id="file-upload"
                              [disabled]="isCurrentUpload"
                              type="file"
                              multiple
                              hidden
                              (change)="detectFiles($event)"
                            />
                          </div>
                        </div>

                        <div *ngIf="!!beforeUploadFiles?.length" class="w-full">
                          <ul>
                            <li
                              class="flex flex-col gap-2 pt-[8px]"
                              *ngFor="
                                let file of beforeUploadFiles;
                                let i = index
                              "
                            >
                              <div class="flex gap-[8px] items-center">
                                <img
                                  src="/assets/massimo/svg/ion_attach-outline.svg"
                                  alt="ion_attach"
                                  class="w-[24px] h-[24px]"
                                />
                                <div class="text-C600 text-b2 font-sans">
                                  {{ file.name }}
                                </div>

                                <div *ngIf="file?.progress === 100">
                                  <span
                                    class="cursor-pointer"
                                    (click)="removeUpload(uploadedFiles[i])"
                                  >
                                    <img
                                      src="/assets/massimo/svg/fi_x.svg"
                                      alt="ion_attach"
                                      class="w-[20px] h-[20px]"
                                    />
                                  </span>
                                </div>
                                <div *ngIf="file?.progress !== 100">
                                  <div
                                    class="uploadedFile"
                                    *ngIf="currentUploadNumber > i"
                                  >
                                    <span
                                      class="cursor-pointer"
                                      (click)="removeUpload(uploadedFiles[i])"
                                    >
                                      <img
                                        src="/assets/massimo/svg/fi_x.svg"
                                        alt="ion_attach"
                                        class="w-[20px] h-[20px]"
                                      />
                                    </span>
                                  </div>

                                  <div *ngIf="i == currentUploadNumber">
                                    <div role="status">
                                      <img
                                        src="/assets/massimo/svg/spinner.svg"
                                        alt="spinner"
                                        class="w-[20px] h-[20px]"
                                        class="animate-spin"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <error-message
                          *ngIf="fileTooBig"
                          message="You can only add 10 files with maximum of 25MB each."
                          extraClass="mt-[8px]"
                        />
                      </div>
                    </div>

                    <div class="w-full" *ngIf="canBid && !isSent">
                      <basic-button
                        [disabled]="!bidForm.valid || isCurrentUpload"
                        [type]="2"
                        title="Submit Application"
                        size="medium"
                        extraClass="!w-full"
                        (click)="submitBid()"
                      />
                    </div>
                  </div>
                </form>
              </ng-container>
              <!-- bid end -->

              <div class="hidden sm:block sm:mt-[48px]">
                <back-to-job-board />
              </div>
            </div>
          </div>
          <div
            class="max-lg:min-w-full max-[1440px]:w-custom-proposals"
            *ngIf="selectedJob.code === 'proposals'"
          >
            <job-proposals-panel />
          </div>

          <div
            *ngIf="
              currentUser &&
              isProvider &&
              !isMyJob &&
              !canBid &&
              yourApplication &&
              !yourApplication.rejected
            "
            class="mt-[24px] sm:mt-[16px]"
          >
            <job-application-panel
              [yourApplication]="yourApplication"
              (btnEvent)="updateDialogWithdrawProposal($event)"
            />
          </div>
        </div>

        <div class="lg:min-w-[350px] lg:max-w-[350px] w-full">
          <job-status-panel
            [job]="job"
            [proposals]="bids?.length"
            [currentUser]="currentUser"
            (leftBtnEvent)="statusLeftClick($event)"
          />

          <div class="max-sm:mt-[16px] sm:hidden">
            <back-to-job-board />
          </div>
        </div>
      </div>
      <!-- <div
        *ngIf="job && isMyJob"
        class="flex flex-col md:flex-row md:justify-between gap-2 md:gap-6 pt-[32px]"
      ></div> -->
      <!-- Modals start -->

      <basic-dialog
        [(visible)]="isSent"
        title="Proposal Successfully Submitted"
        content="Your proposal has been sent to the client.
                If approved, the client will be in touch via messages.
                Goodluck!"
        type="submit"
      />

      <basic-dialog
        [(visible)]="visibleDeleteModal"
        title="Are you sure you want to delete?"
        content="If you delete job post, you will no longer have access to it or receive bids from freelancers.
                You can create a new job listing to receive bids."
        (LeftbtnEvent)="cancelJob($event)"
        (RightbtnEvent)="updateDialogDeleteJob($event)"
        type="delete"
      />

      <basic-dialog
        [(visible)]="visibleWithdrawModal"
        title="Are you sure you want to withdraw your application?"
        content="Your Application will be permanently deleted."
        (LeftbtnEvent)="withdrawProposal($event)"
        (RightbtnEvent)="updateDialogWithdrawProposal($event)"
        type="withdraw"
      />

      <basic-dialog
        [(visible)]="visibleWithdrawSuccessModal"
        title="Your Application Deleted."
        content="You can find another jobs in jobs page."
        type="withdrawSuccess"
      />

      <basic-dialog
        [(visible)]="visibleDeletedSuccessModal"
        title="Job Listing Deleted."
        content="You can create a new job listing to receive bids."
        type="jobListingDeleted"
      />

      <p-dialog
        [(visible)]="visibleLoginModal"
        [modal]="true"
        [draggable]="false"
        [resizable]="false"
        styleClass="mx-[16px]"
        id="login_modal"
      >
        <div class="grid md:grid-cols-2 justify-between">
          <div
            class="absolute top-[20px] right-[20px] md:top-[32px] md:right-[43px]"
          >
            <x-button (click)="updateVisibleLoginModal()" />
          </div>
          <div
            class="flex flex-col justify-center items-center gap-[20px] md:gap-[40px] max-md:my-[40px] max-md:mx-[20px]"
          >
            <img
              src="/assets/massimo/images/logoGray.png"
              alt="logo"
              class="w-[112px] h-[32px] select-none"
            />
            <div class="font-sans flex flex-col gap-[8px]">
              <div class="text-G900 text-center text-h6 md:text-h5 font-medium">
                Sign in to CanWork
              </div>
              <div class="text-G700 text-center text-b2">
                Sign in with your preferred credentials to continue
              </div>
            </div>
            <div class="grid gap-4">
              <ng-container *ngIf="visibleLoginModal">
                <firebase-ui
                  (signInSuccessWithAuthResult)="onFirebaseLogin($event)"
                ></firebase-ui>
              </ng-container>
            </div>
          </div>
          <div class="max-md:hidden select-none">
            <img src="/assets/massimo/images/login.png" alt="" class="w-full" />
          </div>
        </div>
      </p-dialog>

      <!-- Modals -->
      <!-- <div
        id="shareModal"
        class="modal fade"
        *ngIf="job && canSee"
        role="dialog"
      >
        <div class="modal-dialog border-none">
          <div class="modal-content">
            <div class="modal-header text-center border-none">
              <div class="col-12">
                <h5 class="modal-title">SHARE THIS JOB AS A LINK</h5>
              </div>
              <button
                type="button"
                class="close"
                style="position: absolute; right: 15px"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div class="modal-body border-none">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="row px-15"></div>
                    <div class="row px-15">
                      <div class="col-12 text-center">
                        <span>URL:</span>
                        <br />
                        <br />
                        <p class="link-col p-10">
                          {{ link }}
                        </p>
                        <button
                          (click)="copyLink()"
                          class="btn btn-primary mt-5"
                        >
                          COPY LINK
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 text-center">
                        <small id="copied">Copied!</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Modals end -->
    </div>
  </div>

  <!-- template end -->
</main>
