<ng-container *ngIf="yourApplication">
  <div
    class="w-full flex flex-col gap-[16px] md:gap-[24px] sm:rounded-[12px] sm:bg-white border-t sm:border border-t-G200 sm:border-G200 py-[24px] sm:p-[40px]"
  >
    <div
      #contentDiv
      class="w-full flex flex-col gap-[16px] sm:gap-[24px]"
      [@toggleHeight]="isSeeMore ? 'collapsed' : 'expanded'"
    >
      <div class="flex justify-between md:items-center gap-2">
        <div class="flex flex-col gap-1 sm:gap-2">
          <div class="text-h6 font-medium text-G900">Your Application</div>
          <div class="text-b2 text-G500">
            Applied:
            {{ formatDateFromString(yourApplication.timestamp) }}
          </div>
        </div>
        <div class="flex items-center gap-[16px]">
          <div class="hidden sm:block text-b2 font-normal text-G800">
            Status
          </div>
          <div
            class="px-[16px] py-[6px] rounded-3xl text-G800 select-none"
            [ngClass]="{ 'bg-B75': isHired, 'bg-[#FFF8C5]': !isHired }"
          >
            {{ isHired ? 'Accepted' : 'Not viewed' }}
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-[16px]">
        <div class="text-b1 font-medium text-G900">Cover Letter</div>
        <div
          class="text-b1 text-G900"
          [innerHTML]="yourApplication.message | linkify"
        ></div>
      </div>

      <div class="flex flex-col gap-[16px]">
        <hr class="w-full h-[1px] bg-G300 border-none" />
        <div class="text-G900 text-b1 font-medium w-full text-left">
          Your Payment
        </div>

        <div class="flex justify-between gap-[40px]">
          <div class="flex flex-col gap-[8px]">
            <div class="text-b1 font-medium text-G900">Bid Price</div>
            <p class="text-b2 text-G500">
              Total amount the client will see on your proposal
            </p>
          </div>

          <div class="text-b1 font-medium text-G900 whitespace-nowrap">
            ${{ yourApplication.budget }} USD
          </div>
        </div>

        <div class="flex justify-between gap-[40px]">
          <div class="flex flex-col gap-[8px]">
            <div class="text-b1 font-medium text-G900">1% Service Fee</div>
            <p class="text-b2 text-G500">
              CanWork charges 1% fees. The lowest fees ever.
            </p>
          </div>

          <div class="text-b1 font-medium text-G900 whitespace-nowrap">
            ${{ yourApplication.budget / 100 }} USD
          </div>
        </div>

        <div class="flex justify-between gap-[40px]">
          <div class="flex flex-col gap-[8px]">
            <div class="text-b1 font-medium text-G900">Payout</div>
            <p class="text-b2 text-G500">
              Amount you'll receive after service fee charge
            </p>
          </div>

          <div class="text-b1 font-medium text-G900 whitespace-nowrap">
            ${{ yourApplication.budget * 0.99 }} USD
          </div>
        </div>
      </div>

      <div
        *ngIf="
          yourApplication.attachments &&
          yourApplication.attachments?.length !== 0
        "
      >
        <div class="flex flex-col gap-[16px]">
          <hr class="w-full h-[1px] bg-G300 border-none" />
          <div class="text-G900 text-b1 font-medium w-full">Attachment</div>

          <div class="flex flex-col gap-[12px]">
            <attachment-button
              *ngFor="let item of yourApplication.attachments"
              [url]="item.url"
              [name]="item.name"
            />
          </div>
        </div>
      </div>

      <div *ngIf="!isHired" class="w-full sm:flex">
        <basic-button
          [type]="1"
          title="Withdraw Your Application"
          size="small"
          (click)="withdrawButtonClick($event)"
        />
      </div>
    </div>
    <see-more-less-button *ngIf="isHired" [(visible)]="isSeeMore" />
  </div>
</ng-container>
