<ng-container
  *ngIf="isGrid === true; then gridView; else listView"
></ng-container>

<ng-template #gridView>
  <div
    class="w-full h-full rounded-[12px] overflow-hidden relative bg-white hover:bg-G100 active:bg-G200 border border-G200 transition-all duration-200 cursor-pointer select-none font-sans"
    [routerLink]="['/profile/', slug]"
  >
    <div class="w-full h-[110px] md:h-[85px]" [ngClass]="cssClasses"></div>
    <div class="absolute top-[35px] w-full flex justify-center">
      <div class="relative">
        <div
          class="w-[112px] md:w-[120px] h-[112px] md:h-[120px] bg-G200 rounded-full border-4 border-G100 overflow-hidden"
        >
          <img src="{{ avatarUri }}" class="w-full h-full object-cover" />
        </div>
        <img
          src="/assets/massimo/svg/verifiedTick.svg"
          *ngIf="isVerified"
          class="absolute bottom-0 right-0"
        />
      </div>
    </div>
    <div
      class="pt-[50px] md:pt-[93px] md:pb-[32px] pb-[24px] px-[24px] flex flex-col gap-6"
    >
      <div class="flex flex-col gap-2 text-center justify-center items-center">
        <div class="text-G900 text-b1 font-medium line-clamp-1">{{ name }}</div>
        <div class="text-G600 text-b2 font-medium line-clamp-1">
          {{ title }}
        </div>
        <div class="flex gap-2" *ngIf="rating && rating.count > 0">
          <app-star-rating [stars]="rating.average" />
          <div class="text-b2 font-medium text-G700">
            ({{ rating.count }} Jobs)
          </div>
        </div>
      </div>
      <div class="flex gap-2 items-center justify-around">
        <div (click)="submitSearchQuery(skillTags[0])">
          <basic-tag [type]="2" [title]="skillTags[0]" />
        </div>
        <div *ngIf="skillTags.length > 1">
          <basic-tag [type]="2" title="+{{ skillTags.length - 1 }} Others" />
        </div>
      </div>
      <div class="flex justify-between gap-2">
        <div class="flex gap-2">
          <img src="/assets/massimo/svg/location.svg" class="select-none" />
          <!-- <div class="text-G500 text-b1 font-medium">{{ address }}</div> -->
          <!-- to do google address -->
          <div class="text-G500 text-b1 font-medium line-clamp-1">
            {{ timezone }}
          </div>
        </div>
        <div class="flex gap-2">
          <img src="/assets/massimo/svg/money.svg" class="select-none" />
          <div class="text-G500 text-b1 font-medium whitespace-nowrap">
            ${{ hourlyRate }}/hr
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #listView>
  <div
    *ngIf="isSmall !== true"
    class="w-full rounded-[12px] overflow-hidden bg-G100 p-[32px] grid gap-4 font-sans"
  >
    <div class="flex flex-col lg:flex-row lg:justify-between gap-5">
      <div class="flex gap-6">
        <div class="relative">
          <div
            class="bg-G200 rounded-full border-4 border-G100 overflow-hidden"
            [routerLink]="['/profile/', slug]"
          >
            <img
              src="{{ avatarUri }}"
              class="w-[80px] h-[80px] object-cover cursor-pointer hover:brightness-50 transition-all duration-200"
            />
          </div>
          <img
            src="/assets/massimo/svg/verifiedTick.svg"
            *ngIf="isVerified"
            class="absolute bottom-0 right-0 w-[24px]"
          />
        </div>
        <div class="grid gap-2">
          <div class="text-G800 text-h6 font-medium">{{ name }}</div>
          <div class="text-G600 text-b2 font-medium">{{ title }}</div>
          <div class="flex gap-2" *ngIf="rating && rating.count > 0">
            <app-star-rating [stars]="rating.average" />
            <div class="text-b2 font-medium text-G700">
              ({{ rating.count }} Jobs)
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-6 items-start">
        <div class="flex gap-2">
          <img
            src="/assets/massimo/svg/location.svg"
            class="select-none w-[24px]"
          />
          <!-- <div class="text-G500 text-b1 font-medium">{{ address }}</div> -->
          <!-- to do google address -->
          <div class="text-G500 text-b1 font-medium line-clamp-1">
            {{ timezone }}
          </div>
        </div>
        <div class="flex gap-2">
          <img
            src="/assets/massimo/svg/money.svg"
            class="select-none w-[24px]"
          />
          <div class="text-G500 text-b1 font-medium">${{ hourlyRate }}/hr</div>
        </div>
      </div>
    </div>
    <div class="text-b1 text-G700 line-clamp-3">
      {{ bio }}
    </div>
    <div class="flex gap-2 items-center">
      <div (click)="submitSearchQuery(skillTags[0])">
        <basic-tag [type]="2" [title]="skillTags[0]" />
      </div>
      <div *ngIf="skillTags.length > 1">
        <basic-tag [type]="2" title="+{{ skillTags.length - 1 }} Others" />
      </div>
    </div>
  </div>
  <!-- small profile view used i.e. into job details -->
  <div *ngIf="isSmall === true" class="overflow-hidden p-[4px] font-sans">
    <div class="flex flex-row gap-4">
      <div>
        <div class="relative">
          <div
            class="rounded-full border-1 border-G100 overflow-hidden"
            [routerLink]="['/profile/', slug]"
          >
            <img
              src="{{ avatarUri }}"
              class="w-[64px] h-[64px] object-cover cursor-pointer hover:brightness-50 transition-all duration-200"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-[8px]">
        <div class="flex justify-start items-center gap-2">
          <div class="text-G800 text-b1 leading-6 font-medium">{{ name }}</div>
          <img
            src="/assets/massimo/svg/verifiedTick.svg"
            *ngIf="isVerified"
            class="w-[16px] h-[16px]"
          />
        </div>
        <div class="text-b2 text-G800 leading-4">{{ title }}</div>
        <div class="text-b2 text-G800 leading-4">
          {{ timezone }}
        </div>

        <div class="flex gap-1 items-center" *ngIf="rating && rating.count > 0">
          <app-star-rating [stars]="rating.average" />
          <div class="text-b2 text-G700">({{ rating.count }} Jobs)</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
