<div class="font-sans">
  <div
    class="fixed bg-transparent w-full flex justify-center items-center pb-[12px] top-0 z-[1000] transition-all duration-300"
    [ngClass]="{
      'bg-white shadow-md pt-[12px]': isScrolled || isTransfer,
      'pt-[24px]': !isScrolled
    }"
  >
    <div
      class="max-w-[1440px] px-[16px] md:px-[24px] lg:px-[64px] w-full flex justify-between items-center"
    >
      <div class="cursor-pointer" [routerLink]="['/home']">
        <img
          src="{{
            isScrolled || isTransfer
              ? headerSection.logoGraySrc
              : headerSection.logoSrc
          }}"
          class="w-[140px] h-[40px] select-none"
        />
      </div>

      <div class="flex items-center gap-[16px] md:gap-[32px]">
        <div class="hidden lg:flex items-center gap-[32px]">
          <div
            *ngFor="let item of headerSection.website"
            [routerLink]="item.url"
          >
            <link-button
              *ngIf="item.title !== 'Inbox'; else showinbox"
              [type]="isScrolled || isTransfer ? 2 : 1"
              [title]="item.title"
              size="small"
            />
            <ng-template #showinbox>
              <div class="flex">
                <link-button
                  [type]="isScrolled || isTransfer ? 2 : 1"
                  title="Inbox"
                  size="small"
                />
                <p-badge
                  *ngIf="unreadMsgCount > 0"
                  [value]="unreadMsgCount"
                  severity="danger"
                  style="margin-top: -9px"
                ></p-badge>
              </div>
            </ng-template>
          </div>
        </div>
        <div
          class="hidden lg:block w-max h-max"
          *ngIf="!currentUser"
          [routerLink]="['/auth']"
        >
          <link-button
            [type]="isScrolled || isTransfer ? 2 : 1"
            [title]="headerSection.buttonText1"
            size="small"
          />
        </div>
        <div class="hidden md:flex w-max h-max" *ngIf="currentUser">
          <!-- <basic-button [type]="1" title="Log out" size="small" /> -->
          <div
            *ngIf="!bAddress; else showAddress"
            [routerLink]="['/wallet-bnb/assets']"
            [queryParams]="{ connectWallet: 1 }"
          >
            <basic-button [type]="2" title="Connect Wallet" size="small" />
          </div>

          <ng-template #showAddress>
            <div
              [routerLink]="['/wallet-bnb/assets']"
              class="select-none cursor-pointer font-medium text-b2 transition-all duration-300"
              [ngClass]="{
                'text-black': isScrolled || isTransfer,
                'text-white': !isScrolled && !isTransfer
              }"
            >
              {{ bAddress.slice(0, 6) }}...{{ bAddress.slice(-3) }}
            </div>
          </ng-template>
        </div>
        <div class="flex items-center gap-4">
          <div
            class="hidden md:block w-max h-max"
            *ngIf="!currentUser"
            [routerLink]="['/auth']"
          >
            <basic-button
              [type]="1"
              [title]="headerSection.buttonText2"
              size="small"
            />
          </div>
          <div
            class="hidden md:block w-max h-max"
            [routerLink]="['/inbox/post']"
          >
            <basic-button
              [type]="2"
              [title]="headerSection.buttonText3"
              size="small"
            />
          </div>
        </div>

        <div
          class="block md:hidden cursor-pointer"
          (click)="onHamburguerClick($event)"
        >
          <div
            class="w-[34px] h-[3px] rounded-full m-[5px] bg-black"
            [ngClass]="{
              'bg-white': (!isScrolled || !isTransfer) && isHamburguer,
              '!bg-black': isScrolled || isTransfer
            }"
            [@hamburguerX]="isHamburguer ? 'hamburguer' : 'topX'"
          ></div>
          <div
            class="w-[34px] h-[3px] rounded-full m-[5px] bg-black"
            [ngClass]="{
              'bg-white': (!isScrolled || !isTransfer) && isHamburguer,
              '!bg-black': isScrolled || isTransfer
            }"
            [@hamburguerX]="isHamburguer ? 'hamburguer' : 'hide'"
          ></div>
          <div
            class="w-[34px] h-[3px] rounded-full m-[5px] bg-black"
            [ngClass]="{
              'bg-white': (!isScrolled || !isTransfer) && isHamburguer,
              '!bg-black': isScrolled || isTransfer
            }"
            [@hamburguerX]="isHamburguer ? 'hamburguer' : 'bottomX'"
          ></div>
        </div>
        <div *ngIf="currentUser" class="flex justify-center header-avatar">
          <p-dropdown
            [options]="items"
            [(ngModel)]="selectedItem"
            optionLabel="label"
            class="header_menu flex"
          >
            <ng-template pTemplate="selectedItem">
              <app-avatar
                *ngIf="selectedItem"
                [user]="currentUser"
              ></app-avatar>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div
                class="flex items-center"
                *ngIf="
                  item.routerLink &&
                  item.label !== 'Edit Profile' &&
                  item.label !== 'Acting'
                "
                [routerLink]="item.routerLink"
              >
                <img
                  src="{{ '/assets/massimo/svg/header/' + item.icon }}"
                  class="mr-[16px] w-[20px]"
                />
                <span class="font-medium text-G900">{{ item.label }}</span>
              </div>
              <div
                class="flex items-center"
                *ngIf="item.label === 'Edit Profile'"
                [routerLink]="item.routerLink"
                [queryParams]="item.queryParams"
              >
                <img
                  src="{{ '/assets/massimo/svg/header/' + item.icon }}"
                  class="mr-[16px] w-[20px]"
                />
                <span class="font-medium text-G900">{{ item.label }}</span>
              </div>
              <div
                class="flex items-center"
                *ngIf="item.label === 'Acting'"
                (click)="onActingUserType($event)"
              >
                <img
                  src="{{ '/assets/massimo/svg/header/' + item.icon }}"
                  class="mr-[16px] w-[20px]"
                />
                <span class="font-medium text-G900">{{
                  userType === 'Provider' ? 'Act as Client' : 'Act as Provider'
                }}</span>
              </div>

              <div
                class="flex items-center"
                *ngIf="item.label === 'LogOut'"
                (click)="onLogout()"
              >
                <img
                  src="{{ '/assets/massimo/svg/header/' + item.icon }}"
                  class="mr-[16px] w-[20px]"
                />
                <span class="font-medium text-R900">{{ item.label }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <!-- hamburuger menu in mobile view -->

  <div
    class="opacity-0 fixed w-full h-screen top-0 bg-white block md:hidden"
    [ngClass]="isHamburguer ? '-z-[1000]' : 'z-[1001]'"
    [@hamburguerX]="isHamburguer ? 'hamburguer' : 'show'"
  >
    <div class="py-[30px] px-[20px] flex flex-col items-center gap-10">
      <img
        src="{{ headerSection.logoGraySrc }}"
        class="w-[140px] h-[40px] select-none"
      />
      <div class="flex flex-col items-center gap-6">
        <div
          *ngFor="let item of headerSection.website"
          [routerLink]="item.url"
          (click)="onHamburguerClick($event)"
        >
          <link-button [type]="2" [title]="item.title" />
        </div>
        <div
          class="w-max h-max"
          *ngIf="!currentUser"
          [routerLink]="['/auth']"
          (click)="onHamburguerClick($event)"
        >
          <link-button [type]="2" [title]="headerSection.buttonText1" />
        </div>
        <basic-button
          [type]="1"
          [title]="headerSection.buttonText2"
          [extraClass]="'max-md:min-w-full text-center h-max w-full'"
          *ngIf="!currentUser"
          [routerLink]="['/auth']"
          (click)="onHamburguerClick($event)"
        />
        <basic-button
          [type]="2"
          [title]="headerSection.buttonText3"
          [routerLink]="['/inbox/post']"
          (click)="onHamburguerClick($event)"
          [extraClass]="'max-md:min-w-full text-center h-max'"
        />
      </div>
    </div>
    <div
      class="absolute right-[24px] top-[17px] cursor-pointer"
      (click)="onHamburguerClick($event)"
    >
      <img
        src="{{ headerSection.hamburguerXSrc }}"
        class="w-[40px] h-[40px] select-none"
      />
    </div>
  </div>
</div>
