<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  id="headless_modal"
  styleClass="!rounded-b-[0px] !rounded-t-[24px] sm:!rounded-[24px] !bg-G50 sm:max-w-[544px] w-full overflow-hidden max-sm:!mt-[127px] max-sm:absolute max-sm:left-0 max-sm:bottom-0"
>
  <div class="absolute top-[20px] right-[20px] md:top-[32px] md:right-[43px]">
    <x-button (click)="closeModal($event)" />
  </div>
  <div class="flex flex-col gap-[24px] p-[20px] md:px-[40px] md:py-[32px]">
    <div class="flex flex-col gap-[4px]">
      <div class="text-h6 text-G900 font-medium">Connect Wallet</div>
      <div class="text-b2 text-G500 pr-[50px]">
        Select your BNB chain compatible wallet from the list below
      </div>
    </div>
    <!-- <div class="grid grid-cols-4 gap-[16px]">
      <wallet-button [type]="1" />
      <wallet-button [type]="2" />
      <wallet-button [type]="3" />
      <wallet-button [type]="4" />
      <wallet-button [type]="5" />
      <wallet-button [type]="6" />
      <wallet-button [type]="7" />
      <wallet-button [type]="8" />
    </div> -->
    <div class="flex justify-center items-center gap-[16px]">
      <wallet-button
        [type]="1"
        [disabled]="isWalletConnecting"
        (click)="connect(WalletApp.WalletConnectBsc)"
      />
      <wallet-button
        [type]="2"
        [disabled]="isWalletConnecting"
        (click)="connect(WalletApp.MetaMask)"
      />
    </div>
    <div class="text-b2 text-G900 font-medium">What's a wallet ?</div>
  </div>
</p-dialog>
