<div
  class="flex items-center gap-[5px] bg-[#3ac2ff19] transition-all duration-300 rounded-full px-[16px] py-[6px]"
  [ngClass]="{ 'hover:bg-[#33ccff] cursor-pointer': isHoverAllowed }"
>
  <div class="font-sans text-b2 capitalize text-C800">
    {{ skill }}
  </div>
  <div *ngIf="cancel">
    <img src="/assets/massimo/svg/fi_x.svg" alt="fi_x" />
  </div>
</div>
