<ng-container *ngIf="selectedBid">
  <p-dialog
    header="Proposal Details"
    [(visible)]="_visible"
    styleClass="lg:max-w-[900px] md:max-w-[700px] w-full font-sans sm:mx-[20px] max-sm:!pt-[127px] max-sm:absolute max-sm:left-0 max-sm:bottom-0"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    id="filter_modal"
  >
    <div class="flex flex-col items-center">
      <div
        class="flex max-md:flex-col md:justify-between gap-[24px] md:gap-[8px] md:items-center w-full border-y border-G200 px-[16px] py-[16px] md:px-[40px] !font-sans"
      >
        <div class="flex gap-[16px]">
          <div
            class="cursor-pointer"
            [routerLink]="['/profile/alt/', selectedBid.providerId]"
          >
            <app-avatar
              [size]="48"
              [user]="selectedBid.providerInfo"
            ></app-avatar>
          </div>
          <div class="flex flex-col gap-[4px]">
            <div
              class="cursor-pointer line-clamp-1 text-b1 font-medium text-G900"
              [routerLink]="['/profile/alt/', selectedBid.providerId]"
            >
              {{ selectedBid.providerInfo.name }}
              <verified-mark [user]="selectedBid.providerInfo"></verified-mark>
            </div>
            <div class="line-clamp-1 text-b2 text-G700 md:text-G500">
              {{ selectedBid.providerInfo.title }}
            </div>
            <app-star-rating stars="5" />
            <div class="pt-[4px] flex md:hidden items-center gap-[16px]">
              <div class="text-G500 text-b2 font-medium">Bid</div>
              <div class="text-b1 font-medium text-G900">
                ${{ selectedBid.budget }}
              </div>
            </div>
          </div>
        </div>

        <div class="hidden md:flex flex-col gap-[4px]">
          <div class="text-G800 text-b2">Bid</div>
          <div class="text-b1 font-medium text-G900">
            ${{ selectedBid.budget }}
          </div>
        </div>

        <div
          class="flex flex-wrap gap-y-[8px] gap-x-[16px] items-center"
          *ngIf="selectedBid.rejected"
        >
          Declined
        </div>
        <div
          class="flex flex-wrap gap-[8px] md:gap-[16px] items-center"
          *ngIf="!selectedBid.rejected"
        >
          <chat-button
            [routerLink]="['/inbox/chat']"
            [queryParams]="{ address: selectedBid.providerId }"
          />
          <basic-button
            [type]="1"
            (click)="declineClick($event)"
            title="DECLINE"
            size="small"
            extraClass="border text-R900"
          />
          <basic-button
            [type]="2"
            title="ACCEPT"
            size="small"
            (click)="acceptClick($event)"
          />
        </div>
      </div>

      <div
        class="w-full px-[16px] py-[24px] md:py-[16px] md:px-[40px] text-left flex flex-col gap-[16px]"
      >
        <div class="text-b1 font-medium text-G900">Cover Letter</div>
        <p
          class="text-b1 text-G900"
          [innerHTML]="selectedBid.message | linkify"
        ></p>
      </div>

      <div
        *ngIf="selectedBid.attachments && selectedBid.attachments?.length !== 0"
        class="border-t border-G200 w-full px-[16px] py-[24px] md:py-[16px] md:px-[40px]"
      >
        <div class="text-b1 font-medium text-G900">Attachment</div>
        <div class="flex flex-col gap-[12px]">
          <attachment-button
            *ngFor="let item of selectedBid.attachments"
            [url]="item.url"
            [name]="item.name"
          />
        </div>
      </div>
    </div>
  </p-dialog>
</ng-container>
