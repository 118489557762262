<img
  *ngIf="avatarUrl"
  [src]="getAvatarUrl()"
  class="bg-cover rounded-full overflow-hidden"
  alt="avatar"
  [ngClass]="getSizeClass()"
/>
<div
  *ngIf="!avatarUrl"
  class="flex items-center justify-center bg-cyan-300 rounded-full overflow-hidden"
  [ngClass]="getSizeClass()"
>
  <div class="font-sans text-b1 font-medium text-white">
    {{ getInitials() }}
  </div>
</div>
