<section class="bg-white py-30">
  <div class="container">
    <div class="row">
      <div class="col-3 text-right">
        <img class="feedback-icon" src="assets/img/comments.svg" />
      </div>
      <div class="col-9">
        <h4>
          Got feedback? We'd love to hear from you in our Telegram channel.
        </h4>
        <a href="https://t.me/joinchat/GI97FhDD1lf6dh-r9XRdvA" target="_blank">
          <button class="btn btn-primary">GIVE FEEDBACK</button>
        </a>
      </div>
    </div>
  </div>
</section>
