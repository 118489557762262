<div
  class="flex gap-[8px] items-center cursor-pointer select-none text-b1 font-medium text-G900 duration-300 transition-all"
>
  <img
    src="/assets/massimo/svg/commentMessage.svg"
    alt="commentMessage"
    class="w-[20px] h-[20px]"
  />
  Chat
</div>
