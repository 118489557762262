<div class="flex gap-[7px] w-max">
  <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
    <img
      *ngIf="stars > star - 0.5"
      src="/assets/massimo/svg/yellowStar.svg"
      class="w-[20px]"
      alt="yellowStar"
    />
    <img
      *ngIf="star - 0.5 > stars"
      src="/assets/massimo/svg/whiteStar.svg"
      class="w-[20px]"
      alt="whiteStar"
    />
  </ng-container>
</div>
