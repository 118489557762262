<section class="section">
  <div class="container">
    <header class="section-header">
      <h2>Support</h2>
      <p class="lead">
        For all support queries, please email
        <a href="mailto:support@canwork.io" class="hero-text-blue"
          >support@canwork.io</a
        >
      </p>
      <br />
      <h3>Contact Us</h3>
      <p class="lead">We're most active on the following social channels...</p>

      <div class="col">
        <div class="social social-xl text-center">
          <a
            class="social-twitter"
            target="_blank"
            href="https://twitter.com/canwork_"
            ><i class="fab fa-twitter"></i
          ></a>
          <a
            class="social-telegram"
            target="_blank"
            href="https://t.me/joinchat/GI97FhDD1lf6dh-r9XRdvA"
            ><i class="fab fa-telegram"></i
          ></a>
        </div>
      </div>
    </header>
    <app-feedback></app-feedback>
  </div>
</section>
