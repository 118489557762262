<div
  *ngIf="show"
  class="border border-[#d4a72c66] bg-[#FFF8C5] py-[16px] sm:py-[10px] px-[16px] rounded-xl animated fadeIn"
>
  <div *ngIf="type === 1" class="flex justify-between items-center gap-[16px]">
    <div class="flex gap-[16px] sm:gap-[8px] items-center">
      <img
        src="/assets/massimo/svg/alert.svg"
        alt="alert"
        class="w-[16px] h-[16px]"
      />
      {{ message }}
    </div>
    <img
      src="/assets/massimo/svg/cancel_button.svg"
      alt="cancel"
      class="select-none w-[16px] h-[16px] cursor-pointer mt-[3px]"
      (click)="changeViewOption()"
    />
  </div>
  <div *ngIf="type === 2" class="relative md:pr-[32px]">
    <div
      class="w-full flex flex-col xl:flex-row xl:items-center xl:justify-between gap-[8px] xl:gap-[16px]"
    >
      <div class="flex flex-col md:flex-row gap-2">
        <div class="max-md:flex max-md:justify-center">
          <img
            src="/assets/massimo/svg/alert.svg"
            alt="alert"
            class="w-[16px] h-[16px] mt-[2px]"
          />
        </div>
        <div class="text-b2 text-G900 max-md:text-center xl:inline-flex">
          Send&nbsp;
          <span class="font-medium">
            ${{ budget | number : '1.0-0' }} USD
          </span>
          &nbsp;to escrow to begin this project. CanWork accepts major BEP20
          tokens on BNB Chain.&nbsp;
          <!-- <span
            class="underline cursor-pointer select-none whitespace-nowrap hidden xl:block"
          >
            See approved tokens here
          </span> -->
        </div>
      </div>
      <div
        class="flex max-md:justify-center items-center max-md:pl-0 max-xl:pl-[24px]"
      >
        <!-- <div
          class="text-b2 underline cursor-pointer select-none whitespace-nowrap block xl:hidden"
        >
          See approved tokens here
        </div> -->
        <div
          class="text-b2 text-G900 underline cursor-pointer select-none whitespace-nowrap"
          (click)="handleClick($event)"
        >
          Pay Escrow
        </div>
      </div>
    </div>
    <img
      src="/assets/massimo/svg/cancel_button.svg"
      alt="cancel"
      class="select-none w-[16px] h-[16px] cursor-pointer absolute top-0 right-0"
      (click)="changeViewOption()"
    />
  </div>
</div>
